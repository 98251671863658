import { memo } from 'react';
import toast, { Toaster as BaseToaster, ToastBar } from 'react-hot-toast';
import { useTranslation } from 'next-i18next';
import withContainers from 'hoc/withContainers';
import AuthContainer from 'containers/AuthContainer';
import UserContainer from 'containers/UserContainer';
import CloseIcon from 'assets/icons/close.svg';
// @ts-expect-error TS(2614)
import { headerHeight } from 'styles/variables.module.scss';
import { useUpdatedTermsToast } from './useUpdatedTermsToast';
import styles from './Toaster.module.scss';

type ToasterProps = {
  containers: {
    auth: AuthContainer;
    user: UserContainer;
  };
};

const ONE_SECOND = 1_000;
const POSITION_OFFSET = 16;

const Toaster = memo(({ containers: { auth, user } }: ToasterProps) => {
  const { t } = useTranslation();
  useUpdatedTermsToast({ auth, user });

  return (
    <BaseToaster
      position="top-right"
      containerStyle={{
        top: parseInt(headerHeight, 10) + POSITION_OFFSET,
        right: POSITION_OFFSET,
        bottom: POSITION_OFFSET,
        left: POSITION_OFFSET,
        zIndex: 19,
      }}
      toastOptions={{ className: styles.root, duration: 10 * ONE_SECOND }}
    >
      {(aToast) => (
        <ToastBar toast={aToast}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {aToast.type !== 'loading' && (
                <button
                  aria-label={t('general.closeAction')}
                  className={styles.closeAction}
                  type="button"
                  onClick={() => toast.dismiss(aToast.id)}
                >
                  <CloseIcon className={styles.closeIcon} />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </BaseToaster>
  );
});

const WrappedToaster = withContainers({
  auth: AuthContainer,
  user: UserContainer,
})(Toaster);

export { WrappedToaster as Toaster };
