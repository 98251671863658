import { once } from 'lodash';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import toast from 'react-hot-toast';
import { termsUpdatedAt } from 'config';
import { acceptTerms } from 'services/authApi';
import { useRouteData } from 'context/RouteDataProvider';
import { Link } from 'components/common/Link';
import type AuthContainer from 'containers/AuthContainer';
import type UserContainer from 'containers/UserContainer';

type UseUpdatedTermsToastProps = {
  auth: AuthContainer;
  user: UserContainer;
};

const ONE_SECOND = 1_000;

const showUpdatedTermsToast = once((content: JSX.Element) =>
  toast(content, {
    icon: '✍️',
    duration: 60 * ONE_SECOND,
  }),
);

const acceptTermsAgain = once(() => acceptTerms());

const useUpdatedTermsToast = ({ auth, user }: UseUpdatedTermsToastProps) => {
  const { pages } = useRouteData();
  const { t } = useTranslation();
  const { authenticated } = auth.state;
  // @ts-expect-error TS(2339)
  const { termsAcceptedAt } = user.state;

  useEffect(() => {
    if (
      !pages.terms ||
      !authenticated ||
      !termsAcceptedAt ||
      new Date(termsAcceptedAt) >= termsUpdatedAt ||
      new Date() < termsUpdatedAt
    ) {
      return;
    }

    const toastId = showUpdatedTermsToast(
      <Trans i18nKey="misc.updatedTermsNotice" t={t}>
        <Link to={pages.terms.path} onClick={() => toast.dismiss(toastId)}>
          Terms & Conditions
        </Link>{' '}
        have been updated.
      </Trans>,
    );

    acceptTermsAgain();
  }, [authenticated, termsAcceptedAt, pages.terms, t]);
};

export { useUpdatedTermsToast };
