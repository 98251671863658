import cx from 'classnames';
import type { ElementType } from 'react';
import type { PolymorphicComponentProp } from 'types/component';
import styles from './Container.module.scss';

type ContainerProps = {
  className?: string;
  padded?: boolean;
};

const Container = <TElement extends ElementType = 'div'>({
  as,
  className,
  padded = false,
  ...props
}: PolymorphicComponentProp<TElement, ContainerProps>) => {
  const RenderAs = as || 'div';

  return <RenderAs className={cx(styles.root, padded && styles.padded, className)} {...props} />;
};

export { Container };
