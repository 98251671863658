import storageKeys from '../constants/storageKeys';
import store from './store';

const storageKey = storageKeys.cookiesAllowed;

const get = () => store.get(storageKey);

const remember = (cookies) => store.set(storageKey, cookies);

const forget = () => store.remove(storageKey);

export { get, remember, forget };
