import type { Page } from 'types/content';

type CookieCategory = {
  id: 'mandatoryCookies' | 'statsCookies' | 'marketingCookies';
  defaultValue: boolean;
  required?: boolean;
  disabled?: boolean;
  cookiebotCategory: number | number[];
};

const COOKIE_CATEGORIES: CookieCategory[] = [
  {
    id: 'mandatoryCookies',
    required: true,
    disabled: true,
    defaultValue: true,
    cookiebotCategory: 1,
  },
  {
    id: 'statsCookies',
    defaultValue: false,
    cookiebotCategory: 3,
  },
  {
    id: 'marketingCookies',
    defaultValue: false,
    cookiebotCategory: [2, 4],
  },
];

const WHITELIST_PAGES: Page['id'][] = ['cookiePolicy'];

const ELEMENT_ID = {
  title: 'bisquit-banner-title',
  description_0: 'bisquit-banner-description-0',
  description_1: 'bisquit-banner-description-1',
  acceptAll: 'bisquit-banner-accept-all',
};

export { COOKIE_CATEGORIES, WHITELIST_PAGES, ELEMENT_ID };
export type { CookieCategory };
