import { useTranslation } from 'next-i18next';
import { Heading, Stack, Button } from '@carvertical/ui';
import { type ExistingMarkets, isExistingMarket } from 'utils/market';
import { useRouteData } from 'context/RouteDataProvider';
import { ELEMENT_ID } from 'constants/cookies';
import type { BisquitActions } from 'store/useBisquitsStore';
import { BisquitsDescription } from './BisquitsDescription';
import NomNomIllustration from './assets/nom-nom.svg';
import styles from './BisquitsQuickSettings.module.scss';

type BisquitsQuickSettingsProps = BisquitActions;

const NECESSARY_COOKIES_MARKETS: ExistingMarkets = ['france'];

const BisquitsQuickSettings = ({
  onAcceptAll,
  onAcceptNecessary,
  onOpenSettings,
}: BisquitsQuickSettingsProps) => {
  const { market } = useRouteData();
  const { t } = useTranslation();

  const necessaryCookieActionShown = isExistingMarket({
    markets: NECESSARY_COOKIES_MARKETS,
    market,
  });

  return (
    <Stack gap={3} crossAxisAlign="center" className={styles.root}>
      <NomNomIllustration className={styles.illustration} />
      <Stack gap={{ mobileUp: 2, tabletLandscapeUp: 1 }} crossAxisAlign="center">
        <Heading id={ELEMENT_ID.title} variant="s" as="h2">
          {t('cookieNotice.title')}
        </Heading>
        <BisquitsDescription onOpenSettings={onOpenSettings} />
      </Stack>

      <Stack className={styles.buttons} gap={3} mainAxisAlign="center" crossAxisAlign="center">
        <Button
          id={ELEMENT_ID.acceptAll}
          onClick={onAcceptAll}
          className={styles.button}
          variant="black"
          size="l"
        >
          {t('cookieNotice.acceptAllAction')}
        </Button>
        <Button
          onClick={necessaryCookieActionShown ? onAcceptNecessary : onOpenSettings}
          className={styles.button}
          variant="black"
          size="l"
        >
          {necessaryCookieActionShown
            ? t('cookieNotice.acceptNecessary')
            : t('cookieNotice.settingsAction')}
        </Button>
      </Stack>
    </Stack>
  );
};

export { BisquitsQuickSettings };
export type { BisquitsQuickSettingsProps };
