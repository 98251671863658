import { memoize } from 'lodash';

export default {
  clipPathShapes: memoize(() => {
    const base = 'clipPath';
    const prefixes = ['webkit', 'moz', 'ms', 'o'];
    const properties = [base].concat(
      prefixes.map((prefix) => prefix + base.charAt(0).toUpperCase() + base.slice(1)),
    );
    const testElement = document.createElement('testelement');
    const attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)';

    return properties.some((property) => {
      if (testElement.style[property] === '') {
        testElement.style[property] = attribute;
        if (testElement.style[property] !== '') {
          return true;
        }
      }
      return false;
    });
  }),
};
