import PropTypes from 'prop-types';
import { map } from 'lodash';
import Head from 'next/head';
import { getURL } from '../../utils/url';
import { LOCALE_OVERRIDES } from '../../utils/locale';

const HrefLangs = ({ pages }) => (
  <Head>
    {map(pages, (path, locale) => (
      <link
        key={locale}
        rel="alternate"
        href={getURL(path)}
        hrefLang={LOCALE_OVERRIDES[locale] || locale}
      />
    ))}
  </Head>
);

HrefLangs.propTypes = {
  pages: PropTypes.shape(),
};
HrefLangs.defaultProps = {
  pages: {},
};

export default HrefLangs;
