import PropTypes from 'prop-types';
import { castArray, isString } from 'lodash';
import { Fragment } from 'react';
import withTranslate from '../../hoc/withTranslate';
import { useMode } from '../../context/ModeProvider';
import { Container } from './Container';
import { Link } from './Link';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ crumbs, t }) => {
  const { isApp } = useMode();

  if (isApp) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Container>
        <Link className={styles.link} to="/">
          {t('homePage.title')}
        </Link>
        {castArray(crumbs).map((crumb, index) => (
          <Fragment key={index}>
            {' / '}
            {isString(crumb) ? (
              crumb
            ) : (
              <Link className={styles.link} to={crumb.to}>
                {crumb.label}
              </Link>
            )}
          </Fragment>
        ))}
      </Container>
    </div>
  );
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
  ]).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate()(Breadcrumbs);
