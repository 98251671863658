import { Fragment } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Heading, Stack, Text, Button, ToggleSwitch } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouteData } from 'context/RouteDataProvider';
import { Link } from 'components/common/Link';
import * as cookieConsent from 'services/cookieConsent';
import type { SavedCookieCategories } from 'store/useBisquitsStore';
import type { CookieCategory } from 'constants/cookies';
import { bisquitsSettingsSchema } from './schema';
import styles from './BisquitsExtendedSettings.module.scss';

type BisquitsExtendedSettingsProps = {
  cookieCategories: CookieCategory[];
  onAccept: (cookieCategories: SavedCookieCategories | null) => void;
};

const ID = {
  selected: 'bisquit-settings-accept-selected',
  acceptAll: 'bisquit-settings-accept-all',
};

const BisquitsExtendedSettings = ({
  cookieCategories,
  onAccept,
}: BisquitsExtendedSettingsProps) => {
  const { pages } = useRouteData();
  const { t } = useTranslation();
  const selectedCookies = cookieConsent.get();

  const defaultValues = cookieCategories.reduce(
    (values, category) => ({
      ...values,
      [category.id]: category.defaultValue,
    }),
    {},
  );
  const form = useForm<SavedCookieCategories>({
    defaultValues: selectedCookies || defaultValues,
    resolver: zodResolver(bisquitsSettingsSchema),
  });
  const { control, handleSubmit } = form;

  return (
    <div className={styles.root}>
      <Stack gap={{ mobileUp: 4, tabletLandscapeUp: 3 }}>
        <Stack gap={2}>
          <Heading as="h2" variant="s">
            {t('cookieNotice.cookieSettingsLabel')}
          </Heading>
          <Text variant="s">
            {t('cookieNotice.description.intro')}{' '}
            <Trans i18nKey="cookieNotice.description.policy">
              If you would like to see more information, please visit our
              <Link className={styles.link} to={pages.cookiePolicy.path} target="_blank">
                Cookies Policy section.
              </Link>
            </Trans>
          </Text>
        </Stack>

        <Button
          id={ID.acceptAll}
          className={styles.acceptAllButton}
          onClick={() => onAccept(null)}
          variant="black"
          size="l"
        >
          {t('cookieNotice.acceptAllAction')}
        </Button>
      </Stack>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit((values) => onAccept(values))}>
          <div className={styles.formWrapper}>
            {cookieCategories.map(({ disabled = false, id, required = false }, index) => (
              <Fragment key={id}>
                <Stack
                  type="horizontal"
                  mainAxisAlign="spaceBetween"
                  crossAxisAlign="flexStart"
                  className={styles.group}
                >
                  <Stack gap={{ mobileUp: 1, tabletLandscapeUp: 2 }}>
                    <Heading as="h3" variant="xs">
                      {t(`cookieNotice.${id}.label`)}
                    </Heading>
                    <Text variant="s">{t(`cookieNotice.${id}.description`)}</Text>
                  </Stack>
                  <Controller
                    name={id}
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <ToggleSwitch
                        size="l"
                        label={t(`cookieNotice.${id}.label`)}
                        id={name}
                        labelHidden
                        disabled={disabled}
                        checked={value}
                        required={required}
                        onChange={(event) => onChange(event.target.checked)}
                      />
                    )}
                  />
                </Stack>
                {cookieCategories.length - 1 !== index && <div className={styles.spacer} />}
              </Fragment>
            ))}
          </div>
          <div className={styles.footer}>
            <button id={ID.selected} type="submit" className={styles.button}>
              {t('cookieNotice.acceptSelectionAction')}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export { BisquitsExtendedSettings };
export type { BisquitsExtendedSettingsProps };
