import type { AxiosResponse } from 'axios';
import type { Cookie } from 'types/cookies';
import { supportApi } from './base';

const getCookies = async (locale = 'en') => {
  try {
    const { data }: AxiosResponse<Cookie[]> = await supportApi.get<Cookie[]>('/cookiebot', {
      params: {
        locale,
      },
    });

    return data;
  } catch (err) {
    return undefined;
  }
};

export { getCookies };
