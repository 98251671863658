import { create } from 'zustand';
import { flatten, isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import * as cookieConsent from 'services/cookieConsent';
import * as dataLayer from 'services/dataLayer';
import { type CookieCategory, COOKIE_CATEGORIES } from 'constants/cookies';
import { getCookies } from 'services/support-api/cookies';

type View = 'quick' | 'extended';

type SavedCookieCategories = Record<CookieCategory['id'], boolean>;

type BisquitActions = {
  onOpenSettings: () => void;
  onAcceptAll: () => void;
  onAcceptNecessary?: () => void;
};

type BisquitState = {
  view: View;
  setView: (view: View) => void;
  modalShown: boolean;
  setModalShown: (shown: boolean) => void;
  pushToDataLayer: (cookieCategories: SavedCookieCategories) => void;
  saveCookies: (cookieCategories: SavedCookieCategories) => void;
  removeCookies: (cookieCategories: SavedCookieCategories) => Promise<void>;
};

const useBisquitsStore = create<BisquitState>()((set, get) => ({
  view: 'quick',
  modalShown: false,
  setView: (view) => set({ view }),
  setModalShown: (shown) => set({ modalShown: shown }),
  pushToDataLayer: (cookieCategories) => {
    const cookieConsentSelection = Object.entries(cookieCategories)
      .filter(([, value]) => value)
      .map(([key]) => key.replace(/Cookies$/, ''))
      .join(',');

    dataLayer.push('cookieConsent', {
      cookieConsentSelection,
    });
  },
  saveCookies: (cookieCategories) => {
    cookieConsent.remember(cookieCategories);
    get().removeCookies(cookieCategories);
    get().pushToDataLayer(cookieCategories);
    set({ modalShown: false });
  },
  removeCookies: async (cookieCategories) => {
    const rejectedCategoryIds = Object.keys(cookieCategories).filter(
      (key) => !cookieCategories[key as keyof SavedCookieCategories],
    );

    if (isEmpty(rejectedCategoryIds)) {
      return;
    }

    const categoriesToRemove = flatten(
      COOKIE_CATEGORIES.filter((category) => rejectedCategoryIds.includes(category.id)).map(
        ({ cookiebotCategory }) => cookiebotCategory,
      ),
    );

    const availableCookies = await getCookies();

    const cookiesToRemove = availableCookies?.filter((cookie) =>
      categoriesToRemove.includes(+cookie.Category),
    );

    cookiesToRemove?.forEach((cookie) => {
      Cookies.remove(cookie.Name);
    });
  },
}));

export { useBisquitsStore };
export type { View, SavedCookieCategories, BisquitActions };
