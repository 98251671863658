import { z } from 'zod';

const bisquitsSettingsSchema = z.object({
  mandatoryCookies: z.literal(true),
  statsCookies: z.boolean().optional(),
  marketingCookies: z.boolean().optional(),
});

type BisquitsSettingsFormFields = z.infer<typeof bisquitsSettingsSchema>;

export { bisquitsSettingsSchema };
export type { BisquitsSettingsFormFields };
