import { Trans, useTranslation } from 'next-i18next';
import { Stack, Text } from '@carvertical/ui';
import { useRouteData } from 'context/RouteDataProvider';
import { Link } from 'components/common/Link';
import { ELEMENT_ID } from 'constants/cookies';
import type { BisquitActions } from 'store/useBisquitsStore';
import styles from './BisquitsDescription.module.scss';

type BisquitsDescriptionProps = Pick<BisquitActions, 'onOpenSettings'>;

const BisquitsDescription = ({ onOpenSettings }: BisquitsDescriptionProps) => {
  const { t } = useTranslation();
  const { pages } = useRouteData();

  return (
    <Stack gap={2}>
      <Text variant="s" id={ELEMENT_ID.description_0}>
        {t('cookieNotice.description.intro')}
      </Text>
      <Text id={ELEMENT_ID.description_1} variant="s">
        <Trans i18nKey="cookieNotice.description.configure">
          By clicking
          <button type="button" onClick={onOpenSettings} className={styles.button}>
            “Configure”
          </button>
          you will be able to choose which cookies are used.
        </Trans>{' '}
        {pages.cookiePolicy?.path && (
          <Trans i18nKey="cookieNotice.description.policy">
            If you would like to see more information, please visit our
            <Link className={styles.button} to={pages.cookiePolicy.path} target="_blank">
              Cookies Policy section.
            </Link>
          </Trans>
        )}
      </Text>
    </Stack>
  );
};

export { BisquitsDescription };
export type { BisquitsDescriptionProps };
